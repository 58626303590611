import React from 'react';

const CardContainerTelegram = ({project}) => {

	return (
		<div className="tgme_page">
			<div className="tgme_page_photo">
				<a href={project.telegramLink}>
					<img className="tgme_page_photo_image" src={process.env.REACT_APP_API_PUBLIC_URL + project.image}/>
				</a>
			</div>
			<div className="tgme_page_title"><span dir="auto">{project.name}</span></div>
			<div className="tgme_page_description description_fix" dangerouslySetInnerHTML={{__html: project.description}}/>
			<div className="tgme_page_action">
				<a className="tgme_action_button_new shine" href={project.telegramLink}>JOIN</a>
			</div>
		</div>
	);
};

export default CardContainerTelegram;