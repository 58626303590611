import './App.css';
import React, {useEffect, useState} from 'react';
import Loader from "./components/Loader";
// import GradientContainer from "./components/GradientContainer";
// import NotFound from "./components/NotFound";
// import CardContainer from "./components/CardContainer";
import {getProject} from "./http/project";
import CardContainerTelegram from "./components/CardContainerTelegram";

const App = () => {
	const [loader, setLoader] = useState({status: true, message: 'Loading...'});
	const [project, setProject] = useState({
		name: '',
		subdomain: '',
		image: '',
		description: '',
		links: []
	});

	useEffect(() => {
		setLoader({status: true, message: 'Loading...'})
		getProject(window.location.hostname.split('.').slice(0, -2).join('.'))
		// getProject('trade')
			.then((data) => {
				if (data.customCode) {
					const _body = document.createElement('body');
					_body.innerHTML = data.customCode;

					const scripts = _body.querySelectorAll('script');
					const noScripts = _body.querySelectorAll('noscript');

					scripts.forEach(script => {
						const _el = document.createElement('script');
						_el.innerHTML = script.innerText;
						document.head.appendChild(_el);
					});
					noScripts.forEach(noScript => {
						const _el = document.createElement('noscript');
						_el.innerHTML = noScript.innerText;
						document.head.appendChild(noScript);
					});

					_body.remove();
				}

				setProject({...data});
			})
			.catch(console.error)
			.finally(() => setLoader(null));
	}, []);

	return (
		<>
			{
				(loader === null) && <CardContainerTelegram project={project}/>
			}
			{
				loader !== null && <Loader loader={loader}/>
			}
		</>
	);
};

export default App;